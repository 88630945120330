'use client';

import { ChangeEvent, FormEvent } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  Typography,
} from '@wishket/design-system';
import Link from 'next/link';

import { ContentsContainer, SocialFormButton } from '@/shared/Components';
import {
  LoginInputErrorType,
  LoginInputValueType,
  SocialLoginProvider,
} from '@/shared/types';
import PATH from '@/shared/constants/path';
import { useNextUrl } from '@/shared/hooks';

interface LoginFormProps {
  inputValue: LoginInputValueType;
  inputError: LoginInputErrorType;
  handleChange: {
    emailOrId: (e: ChangeEvent<HTMLInputElement>) => void;
    password: (e: ChangeEvent<HTMLInputElement>) => void;
    remember: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  submitLogin: (e: FormEvent<HTMLFormElement>) => void;
  submitSocialLogin: (provider: SocialLoginProvider) => Promise<void>;
  isLoginError?: boolean;
  loginErrorMessage?: string;
}

const LoginForm = ({
  inputValue,
  inputError,
  handleChange,
  submitLogin,
  submitSocialLogin,
  isLoginError,
  loginErrorMessage,
}: LoginFormProps) => {
  const { withNextUrl } = useNextUrl();

  return (
    <ContentsContainer title="로그인">
      <Box className="flex w-full flex-col items-center justify-center">
        <form
          onSubmit={submitLogin}
          data-testid="login-form-contents"
          className="w-full"
        >
          <Box className="flex flex-col gap-6 pb-6">
            <TextField
              name="emailOrId"
              variant="lable"
              lable="아이디 또는 이메일"
              value={inputValue.emailOrId}
              onChange={handleChange.emailOrId}
              isError={!inputError.emailOrId.valid}
              errorMessage={inputError.emailOrId.errorMessage}
            />
            <TextField
              name="password"
              variant="lable"
              lable="비밀번호"
              type="password"
              value={inputValue.password}
              onChange={handleChange.password}
              isError={!inputError.password.valid}
              errorMessage={inputError.password.errorMessage}
              maxLength={32}
              isMaxLengthVisible={false}
            />
          </Box>
          <Box className="flex items-center justify-between pb-8">
            <label className="flex h-full items-center justify-start gap-3">
              <Checkbox
                onChange={handleChange.remember}
                name="remember"
                className="flex items-center"
                checked={inputValue.remember}
              />
              <Typography
                variant="body1"
                className="cursor-pointer text-w-gray-900"
              >
                로그인 상태 유지
              </Typography>
            </label>
            <Link
              data-testid="find-password-link"
              href={PATH.PASSWORD_FIND}
              className="no-underline"
            >
              <Typography
                variant="body1"
                className="font-medium text-w-primary"
              >
                비밀번호 찾기
              </Typography>
            </Link>
          </Box>
          {isLoginError && (
            <Box className="mb-8 flex flex-col whitespace-break-spaces text-w-red-500">
              <Typography variant="body3">{loginErrorMessage}</Typography>
            </Box>
          )}
          <Box data-testid="login-button" className="pb-4">
            <Button
              type="submit"
              variant="solid"
              size="md"
              className="w-full rounded-xl"
            >
              <Typography variant="body1">로그인</Typography>
            </Button>
          </Box>
        </form>

        <Box className="flex w-full items-center justify-center">
          <Divider className="w-[40%]" />
          <Typography
            variant="caption1"
            className="mx-3 flex w-14 justify-center text-w-gray-500"
          >
            또는
          </Typography>
          <Divider className="w-[40%]" />
        </Box>
        <Box className="flex w-full flex-col gap-4 pt-4">
          <SocialFormButton
            type="google"
            text="구글로 로그인"
            onClick={() => submitSocialLogin('google')}
          />
          <SocialFormButton
            type="facebook"
            text="페이스북으로 로그인"
            onClick={() => submitSocialLogin('facebook')}
          />
        </Box>
      </Box>
      <Box className="mt-6 flex w-full items-end justify-center gap-1">
        <Typography variant="body1" className="text-w-gray-600">
          아직 위시켓 통합회원이 아니신가요?
        </Typography>
        <Link
          href={withNextUrl(PATH.SIGNUP)}
          className="no-underline"
          data-testid="sign-up-link"
        >
          <Typography variant="body1" className="font-medium text-w-primary">
            회원가입
          </Typography>
        </Link>
      </Box>
    </ContentsContainer>
  );
};

export default LoginForm;
