'use client';

import { Box, Button, Typography } from '@wishket/design-system';
import { useRouter } from 'next/navigation';

import { Icon } from '@/shared/Components';
import { PATH } from '@/shared/constants';

interface WithdrawalRequestCompleteProps {
  fetchCancelWithdrawal: () => Promise<void>;
  dueDate?: string;
}

const WithdrawalRequestComplete = ({
  fetchCancelWithdrawal,
  dueDate,
}: WithdrawalRequestCompleteProps) => {
  const router = useRouter();

  const handleCancelWithdrawal = async () => {
    await fetchCancelWithdrawal();
    router.replace(PATH.HOME);
  };

  return (
    <Box
      className="flex flex-col gap-10"
      data-testid="feature-withdrawal-request-complete"
    >
      <Typography
        variant="subTitle1"
        className="text-w-gray-900 desktop:text-2xl"
      >
        회원 탈퇴 신청이 완료되었어요.
      </Typography>

      <Icon name="account_leave" />
      <Typography
        variant="body1"
        className="whitespace-pre-wrap font-normal text-w-gray-600"
      >{`${dueDate} 계정 탈퇴 처리가 완료됩니다.\n탈퇴 처리가 완료되면, 사용 중인 아이디 및 이메일로는 서비스 이용이 불가합니다.`}</Typography>
      <Button
        variant="solid"
        className="box-border w-full rounded-xl border-w-bluegray-200 bg-w-bluegray-200 ring-w-bluegray-200 hover:border-w-bluegray-300 hover:bg-w-bluegray-300 hover:ring-w-bluegray-300 desktop:w-[170px]"
        onClick={handleCancelWithdrawal}
        needThrottle
      >
        회원 탈퇴 신청 취소
      </Button>
    </Box>
  );
};

export default WithdrawalRequestComplete;
