import { Box, Checkbox, SystemIcon, Typography } from '@wishket/design-system';
import Link from 'next/link';
import { ChangeEvent } from 'react';
import { twJoin } from 'tailwind-merge';

import { PATH } from '@/shared/constants';
import { MarketingAgreementType } from '@/shared/types';

interface SignUpAgreementCheckboxProps {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
}

interface ToolTipProps {
  title: string;
  description: string;
  closeTooltip: () => void;
}

interface MarketingAgreementCheckboxesProps {
  isCheckPolicyAgreement: boolean;
  isCheckMarketingUseAgreement: boolean;
  isCheckMarketingSubscribeAgreement: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  openPolicyModal: (type: MarketingAgreementType) => void;
  isError?: boolean;
}

export const ToolTip = ({ title, description, closeTooltip }: ToolTipProps) => {
  return (
    <Box className="flex w-full flex-col gap-2 rounded-2xl bg-white px-5 py-4 shadow-contents-container tablet:w-80 desktop:w-80">
      <Box className="flex w-full items-start justify-between gap-4">
        <Typography variant="body3" className="text-w-gray-900">
          {title}
        </Typography>
        <Box onClick={closeTooltip} className="mt-0.5 desktop:hidden">
          <SystemIcon name="medium_delete" />
        </Box>
      </Box>
      <Typography variant="body3" className="text-w-gray-400">
        {description}
      </Typography>
    </Box>
  );
};

export const MarketingAgreementCheckboxes = ({
  isCheckPolicyAgreement,
  isCheckMarketingUseAgreement,
  isCheckMarketingSubscribeAgreement,
  handleChange,
  openPolicyModal,
  isError = false,
}: MarketingAgreementCheckboxesProps) => {
  const isAllAgreementChecked =
    isCheckPolicyAgreement &&
    isCheckMarketingUseAgreement &&
    isCheckMarketingSubscribeAgreement;

  const clickAllAgreement = () => {
    handleChange({
      target: {
        checked: !isAllAgreementChecked,
        name: 'allAgreement',
      },
    } as ChangeEvent<HTMLInputElement>);
  };

  return (
    <Box
      className="flex flex-col gap-4"
      data-testid="signup-marketing-agreement-checkboxes"
    >
      <Box className="flex items-center justify-start gap-3">
        <Checkbox
          checked={isAllAgreementChecked}
          onChange={clickAllAgreement}
          name="allAgreement"
          id="allAgreement"
          className="cursor-pointer"
        />
        <label htmlFor="allAgreement" className="cursor-pointer">
          <Typography
            variant="subTitle2"
            className="font-medium text-w-gray-900"
          >
            전체 동의하기
          </Typography>
        </label>
      </Box>
      <Box>
        <Box className="flex items-start justify-between">
          <label className="flex cursor-pointer items-start justify-start gap-3">
            <Checkbox
              type="sub"
              checked={isCheckPolicyAgreement}
              onChange={handleChange}
              name="policyAgreement"
              className={twJoin('h-full py-1', isError && 'text-w-red-500')}
            />
            <Typography variant="body1" className="break-keep text-w-gray-900">
              이용약관 및 개인정보 처리방침 동의{' '}
              <span className="text-w-gray-400">(필수)</span>
            </Typography>
          </label>
          <Link
            href={PATH.TERMS_SERVICE}
            target="_blank"
            data-testid="signup-marketing-agreement-checkboxes--terms-service-link"
          >
            <Box className="h-full py-[5px]">
              <SystemIcon
                name="medium_arrow_right"
                className="cursor-pointer text-w-gray-400"
              />
            </Box>
          </Link>
        </Box>

        {isError && (
          <Box className="w-full flex-none px-[30px]">
            <Typography
              variant="body2"
              className="break-keep font-normal text-w-red-500"
            >
              위시켓 서비스 이용을 위해서 반드시 동의를 해주셔야 합니다.
            </Typography>
          </Box>
        )}
      </Box>
      <Box className="flex items-center justify-between">
        <label className="flex cursor-pointer items-center justify-start gap-3">
          <Checkbox
            type="sub"
            checked={isCheckMarketingUseAgreement}
            onChange={handleChange}
            name="marketingUseAgreement"
          />
          <Typography variant="body1" className="text-w-gray-900">
            개인정보 마케팅 활용 동의{' '}
            <span className="text-w-gray-400">(선택)</span>
          </Typography>
        </label>
        <Box
          className="cursor-pointer"
          onClick={() => openPolicyModal(MarketingAgreementType.USE)}
          data-testid="signup-marketing-agreement-checkboxes--marketing-use-agreement-link"
        >
          <SystemIcon name="medium_arrow_right" className="text-w-gray-400" />
        </Box>
      </Box>
      <Box className="grid grid-cols-[min-content,1fr,min-content] grid-rows-[min-content,min-content] gap-x-3">
        <Box className="py-1">
          <Checkbox
            type="sub"
            checked={isCheckMarketingSubscribeAgreement}
            onChange={handleChange}
            id="marketingSubscribeAgreement"
            name="marketingSubscribeAgreement"
          />
        </Box>
        <label htmlFor="marketingSubscribeAgreement" className="w-fit">
          <Typography
            variant="body1"
            className="inline-block w-fit cursor-pointer text-w-gray-900"
          >
            마케팅 정보 수신 동의{' '}
            <span className="text-w-gray-400">(선택)</span>
          </Typography>
        </label>
        <Box
          className="cursor-pointer py-[5px]"
          onClick={() => openPolicyModal(MarketingAgreementType.SUBSCRIBE)}
          data-testid="signup-marketing-agreement-checkboxes--marketing-subscribe-agreement-link"
        >
          <SystemIcon name="medium_arrow_right" className="text-w-gray-400" />
        </Box>
        <Box />
        <Typography
          variant="body2"
          className="col-start-2 col-end-3 text-w-gray-600"
        >
          맞춤 추천, 뉴스레터 등 유용한 혜택을 제공받을 수 있습니다.
        </Typography>
      </Box>
    </Box>
  );
};

export const SignUpAgreementCheckbox = ({
  checked,
  onChange,
  isError = false,
}: SignUpAgreementCheckboxProps) => {
  return (
    <label className="flex w-full gap-4" data-testid="signup-agreement-label">
      <Box
        className="flex h-[26px] items-center"
        data-testid="signup-agreement-checkbox-root"
      >
        <Checkbox
          checked={checked}
          onChange={onChange}
          name="policyAgreement"
          data-testid="account-signup-form--policy-checkbox"
        />
      </Box>
      <Box className="flex h-fit flex-col gap-1.5 text-w-gray-900">
        <Typography variant="body1">
          <Link
            target="_blank"
            href={PATH.TERMS_SERVICE}
            className="font-medium text-w-primary no-underline"
          >
            이용약관
          </Link>
          &nbsp;및&nbsp;
          <Link
            target="_blank"
            href={PATH.TERMS_PRIVACY}
            className="font-medium text-w-primary no-underline"
          >
            개인정보 처리방침
          </Link>
          에 동의합니다.
        </Typography>
        {isError && (
          <Typography variant="body3" className="text-w-red-500">
            위시켓 서비스 이용을 위해서 반드시 동의를 해주셔야 합니다.
          </Typography>
        )}
      </Box>
    </label>
  );
};
