'use client';

import Link from 'next/link';
import { Box, Typography } from '@wishket/design-system';
import { twMerge } from 'tailwind-merge';

interface TopBarProps {
  pageTitle: string;
  isAvailable: boolean;
  path: string;
}

const TopBar = ({ pageTitle, isAvailable, path }: TopBarProps) => {
  return (
    <Box
      className="flex justify-center grid-in-ci desktop:block"
      data-testid="topbar"
    >
      <Link
        href={isAvailable ? path : ''}
        className={twMerge(!isAvailable && 'cursor-default')}
        prefetch={false}
      >
        <Typography
          variant="subTitle1"
          className="font-medium text-w-gray-900 desktop:text-base"
        >
          {pageTitle}
        </Typography>
      </Link>
    </Box>
  );
};

export default TopBar;
