'use client';

import { Box, Button, TextField, Typography } from '@wishket/design-system';
import { ChangeEvent, FormEvent } from 'react';

import { SignUpInputErrorType, SignUpInputValueType } from '@/shared/types';
import { ContentsContainer } from '@/shared/Components';

interface PasswordFindFormProps {
  inputValue?: Pick<SignUpInputValueType, 'email'>;
  inputError?: Pick<SignUpInputErrorType, 'email'>;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (e: FormEvent<HTMLFormElement>) => void;
}

const PasswordFindForm = ({
  inputError,
  inputValue,
  handleChange,
  handleSubmit,
}: PasswordFindFormProps) => {
  return (
    <ContentsContainer
      title="비밀번호 찾기"
      isDivide
      testId="feature-password-find-form"
    >
      <form
        onSubmit={handleSubmit}
        data-testid="password-find-form"
        className="flex flex-col gap-8"
      >
        <Box className="whitespace-break-spaces break-keep tablet:whitespace-normal desktop:whitespace-pre-line">
          <Typography
            variant="body1"
            className="text-w-gray-700"
          >{`위시켓 통합회원에 가입된 이메일을 입력하시면,\n비밀번호 재설정 메일을 전송해드립니다.`}</Typography>
        </Box>
        <Box className="w-full">
          <TextField
            name="email"
            variant="lable"
            lable="이메일"
            value={inputValue?.email}
            onChange={handleChange}
            isError={!inputError?.email?.valid}
            errorMessage={inputError?.email?.errorMessage}
          />
        </Box>
        <Button className="w-full" size="md" variant="solid" type="submit">
          비밀번호 재설정 메일 보내기
        </Button>
      </form>
    </ContentsContainer>
  );
};

export default PasswordFindForm;
