'use client';

import { Box, Button, Modal, Typography } from '@wishket/design-system';

import { useDeviceCheck } from '@/shared/hooks';
import { MarketingAgreementType } from '@/shared/types';

interface MarketingWithdrawalModalProps {
  isOpen: boolean;
  onAgree: () => void;
  onClose: () => void;
  type: MarketingAgreementType | null;
}

const MarketingWithdrawalModal = ({
  isOpen,
  onAgree,
  onClose,
  type,
}: MarketingWithdrawalModalProps) => {
  const { isMobile, isLoading } = useDeviceCheck();

  if (isLoading) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isMobile ? 'full' : 'sm'}
      isFixedHeight={false}
    >
      <Box
        className="flex flex-col items-start justify-center gap-8 p-8"
        data-testid="marketing-withdrawal-modal"
      >
        <Box className="flex flex-col items-start justify-center gap-6">
          <Typography
            variant="subTitle2"
            className="font-medium text-w-gray-900"
          >
            {type === MarketingAgreementType.USE && '개인정보 마케팅 활용 동의'}
            {type === MarketingAgreementType.SUBSCRIBE &&
              '마케팅 정보 수신 동의'}
          </Typography>
          <Box className="flex flex-col items-start justify-center gap-4">
            <Typography variant="body1" className="break-keep text-w-gray-800">
              동의 철회 시, 위시켓 모든 서비스에서 제공하는 다양한 혜택 정보를
              더 이상 받을 수 없게 됩니다.정말 철회하시겠습니까?
            </Typography>
            <Box className="rounded-xl bg-w-gray-10 px-5 py-4">
              <Typography
                variant="body3"
                className="inline-block break-keep text-w-gray-600"
              >
                선택 항목에 동의하지 않더라도 위시켓 모든 서비스를 이용할 수
                있으나 거부 시, 상기 이용목적에 명시된 서비스는 받으실 수
                없습니다.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="flex w-full items-center justify-between gap-4">
          <Button
            type="button"
            variant="solid"
            size="md"
            className="w-full border-w-bluegray-200 bg-w-bluegray-200 hover:border-w-bluegray-300 hover:bg-w-bluegray-300 tablet:w-[160px]"
            onClick={onAgree}
            data-testid="marketing-withdrawal-modal-withdrawal-button"
          >
            철회하기
          </Button>
          <Button
            type="button"
            variant="solid"
            size="md"
            className="w-full border-w-orange-500 bg-w-orange-500 hover:border-w-orange-700 hover:bg-w-orange-700 tablet:w-[160px]"
            onClick={onClose}
            data-testid="marketing-withdrawal-modal-close-button"
          >
            닫기
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MarketingWithdrawalModal;
