'use client';

import { Box, Button, Typography } from '@wishket/design-system';
import Link from 'next/link';

import { ContentsContainer, Icon } from '@/shared/Components';
import { PATH } from '@/shared/constants';
import { useNextUrl } from '@/shared/hooks';

const PasswordResetComplete = () => {
  const { withNextUrl } = useNextUrl();

  return (
    <ContentsContainer
      title="비밀번호 재설정"
      isDivide
      testId="password-reset-complete"
    >
      <Box className="flex flex-col items-center gap-6">
        <Icon name="password_lock" />
        <Box className="flex flex-col gap-3">
          <Typography variant="subTitle2" className="text-center">
            비밀번호가 안전하게 변경되었어요!
          </Typography>
          <Typography
            variant="body1"
            className="whitespace-pre-line text-center text-w-gray-900"
          >
            모든 위시켓 서비스를 안전하게 이용할 수 있습니다.
          </Typography>
        </Box>
      </Box>
      <Link
        href={withNextUrl(PATH.LOGIN)}
        className="w-full text-inherit no-underline"
      >
        <Button className="w-full" size="md" variant="solid">
          로그인하러 가기
        </Button>
      </Link>
    </ContentsContainer>
  );
};

export default PasswordResetComplete;
