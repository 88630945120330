'use client';

import {
  Box,
  Divider,
  ServiceLogo,
  SystemIcon,
  Typography,
} from '@wishket/design-system';
import { useRef, useState } from 'react';
import Link from 'next/link';

import { menuList } from './ServiceMenu.constants';
import { MenuItem } from './ServiceMenu.types';

import { useClickOutside } from '@/shared/hooks';
import { notifyToast } from '@/shared/utils';

interface ServiceMenuPopOverProps {
  isEmailVerified: boolean;
}

const ServiceMenuItem = ({ menuItem }: { menuItem: MenuItem }) => {
  return (
    <Link href={menuItem.link}>
      <Box className="flex items-center gap-4">
        <Box className="rounded-full p-2 ring-1 ring-w-gray-200">
          <ServiceLogo name={menuItem.icon} />
        </Box>
        <Box className="flex flex-1 flex-col">
          <Box className="flex items-center gap-1.5">
            <Typography variant="body2" className="text-w-gray-900">
              {menuItem.name}
            </Typography>
            {menuItem.badge && (
              <Typography
                variant="caption2"
                className="w-fit rounded-full bg-w-gray-200 px-1.5 py-0.5 text-w-gray-600"
              >
                준비 중
              </Typography>
            )}
          </Box>
          <Typography variant="body3" className="text-w-gray-600">
            {menuItem.description}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

const ServiceMenuPopOver = () => {
  return (
    <Box
      data-testid="topbar-service-menu-popover"
      className="absolute -right-4 top-9 grid w-[300px] grid-cols-1 gap-4 rounded-2xl bg-w-white px-6 py-5 shadow-contents-container"
    >
      {menuList.map((menu, index) => (
        <>
          <ServiceMenuItem key={index} menuItem={menu} />
          {index !== menuList.length - 1 && <Divider />}
        </>
      ))}
    </Box>
  );
};

const ServiceMenu = ({ isEmailVerified }: ServiceMenuPopOverProps) => {
  const [isServiceMenuOpen, setIsServiceMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => {
    setIsServiceMenuOpen(false);
  });

  return (
    <div
      data-testid="topbar-service-menu"
      ref={ref}
      className="relative z-20 flex cursor-pointer items-center gap-2"
      onClick={() => {
        if (isEmailVerified) setIsServiceMenuOpen(!isServiceMenuOpen);
        else
          notifyToast({
            type: 'error',
            message: '이메일 인증 후 위시켓 통합회원 서비스 이용이 가능합니다.',
          });
      }}
    >
      <Typography variant="body2" className="text-w-gray-600">
        서비스 전체보기
      </Typography>
      <SystemIcon
        name={isServiceMenuOpen ? 'small_arrow_up' : 'small_arrow_down'}
        className="text-w-gray-600"
        testId="topbar-service-menu--arrow-icon"
      />
      {isServiceMenuOpen && <ServiceMenuPopOver />}
    </div>
  );
};

export default ServiceMenu;
