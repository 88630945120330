import { Box, Typography } from '@wishket/design-system';
import React from 'react';

export const BetaServiceBadge: React.FC<{ className: string }> = ({
  className,
}) => {
  return (
    <svg
      width="27"
      height="14"
      viewBox="0 0 27 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.04785 10H0.786133V2.80273H4.28223C5.16439 2.81576 5.78939 3.07129 6.15723 3.56934C6.37858 3.87533 6.48926 4.24154 6.48926 4.66797C6.48926 5.10742 6.37858 5.46061 6.15723 5.72754C6.03353 5.87728 5.85124 6.014 5.61035 6.1377C5.97819 6.27116 6.25488 6.48275 6.44043 6.77246C6.62923 7.06217 6.72363 7.41374 6.72363 7.82715C6.72363 8.25358 6.61621 8.63607 6.40137 8.97461C6.26465 9.19922 6.09375 9.38802 5.88867 9.54102C5.65755 9.7168 5.38411 9.83724 5.06836 9.90234C4.75586 9.96745 4.41569 10 4.04785 10ZM4.01367 6.83105H2.22168V8.75H3.98926C4.30501 8.75 4.55078 8.70768 4.72656 8.62305C5.04557 8.4668 5.20508 8.16732 5.20508 7.72461C5.20508 7.35026 5.05046 7.0931 4.74121 6.95312C4.56868 6.875 4.32617 6.83431 4.01367 6.83105ZM4.76074 5.45898C4.95605 5.3418 5.05371 5.13184 5.05371 4.8291C5.05371 4.49382 4.9235 4.27246 4.66309 4.16504C4.43848 4.09017 4.15202 4.05273 3.80371 4.05273H2.22168V5.63965H3.98926C4.30501 5.63965 4.56217 5.57943 4.76074 5.45898ZM12.998 6.85547H9.50195V8.70605H13.4863V10H8.03223V2.80273H13.3105V4.07715H9.50195V5.60547H12.998V6.85547ZM19.8877 2.80273V4.07715H17.7344V10H16.2207V4.07715H14.0576V2.80273H19.8877ZM24.2432 8.52051H21.5918L21.0938 10H19.5215L22.0898 2.80273H23.7891L26.3379 10H24.707L24.2432 8.52051ZM23.8232 7.28027L22.9248 4.44824L21.9971 7.28027H23.8232Z"
        fill="#006DFF"
      />
    </svg>
  );
};

export const BulletListItem = ({ description }: { description: string }) => {
  return (
    <Box className="flex items-start gap-2">
      <Box className="mt-3 flex items-center justify-center">
        <div className="h-0.5 w-0.5 shrink-0 rounded-full bg-w-gray-600" />
      </Box>
      <Typography
        variant="body1"
        className="flex whitespace-pre-wrap text-justify font-normal text-w-gray-600"
      >
        {description}
      </Typography>
    </Box>
  );
};
