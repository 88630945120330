'use client';

import {
  Box,
  Button,
  Checkbox,
  ServiceLogo,
  Typography,
} from '@wishket/design-system';
import Link from 'next/link';
import { ChangeEvent, FormEvent } from 'react';

import { informationList } from './WithdrawalRequest.constants';
import { BetaServiceBadge, BulletListItem } from './WithdrawalRequest.parts';

import { InputErrorStatus } from '@/shared/types';
import PATH from '@/shared/constants/path';
import { useNextUrl } from '@/shared/hooks';

interface WithdrawalAgreementProps {
  agreement: boolean;
  agreementError: InputErrorStatus;
  onAgreementChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleWithdrawal: (e: FormEvent<HTMLFormElement>) => void;
}

const WithdrawalRequest = ({
  agreement,
  agreementError,
  onAgreementChange,
  handleWithdrawal,
}: WithdrawalAgreementProps) => {
  const { withNextUrl } = useNextUrl();

  return (
    <Box
      className="flex flex-col gap-10"
      data-testid="feature-withdrawal-request"
    >
      <Typography
        variant="subTitle1"
        className="text-w-gray-900 desktop:text-2xl"
      >
        회원 탈퇴
      </Typography>
      <form
        className="box-border flex flex-col gap-10 text-w-gray-600"
        onSubmit={handleWithdrawal}
      >
        <Typography variant="body1" className="font-medium text-w-gray-900">
          탈퇴 시 유의사항
        </Typography>
        <BulletListItem
          description={`위시켓 통합회원 계정은 모든 위시켓 서비스(위시켓, 위시켓 잡스, 요즘IT)를 함께 이용하실 수 있는 통합 계정으로 탈퇴 시 계정 정보 및 모든 정보가 삭제되며, 추후 재가입 하더라도 복구되지 않습니다.`}
        />
        <Box className="flex justify-center gap-4 rounded-xl bg-w-gray-10 py-6">
          <Box className="flex justify-center gap-0.5">
            <ServiceLogo name="wishket_symbol" />
            <Typography variant="body2" className="text-w-gray-900">
              위시켓
            </Typography>
          </Box>
          <Box className="flex justify-center gap-0.5">
            <ServiceLogo name="jobs_symbol" />
            <Typography variant="body2" className="text-w-gray-900">
              위시켓잡스
            </Typography>
            <BetaServiceBadge className="pt-0.5" />
          </Box>
          <Box className="flex justify-center gap-0.5">
            <ServiceLogo name="yozmIT_symbol" />
            <Typography variant="body2" className="text-w-gray-900">
              요즘IT
            </Typography>
          </Box>
        </Box>
        <BulletListItem
          description={`관련 법령에 따라 아래 종류에 해당하는 기록이 있는 경우, 회원 정보 및 관련 기록을 정해진 기간동안 보관합니다.`}
        />
        <BulletListItem
          description={`보관기간 내에는 동일한 아이디 및 이메일로 재가입이 불가능하며, 이후 보관기간이 종료되었을 시 회원 정보 및 관련 기록은 지체없이 파기됩니다.`}
        />
        <Box>
          <Box className="flex items-center justify-between border-b border-w-gray-900 py-3 font-medium text-w-gray-900">
            <Typography variant="body2">보관정보 종류</Typography>
            <Typography variant="body2" className="w-16">
              기간
            </Typography>
          </Box>
          {informationList.map((item) => (
            <Box
              key={`${item.title}-${item.duration}`}
              className="flex items-center justify-between border-b border-w-gray-200 py-3 text-w-gray-600"
            >
              <Typography variant="body1" className="w-full pr-6 font-normal">
                {item.title}
              </Typography>
              <Typography variant="body1" className="w-16 shrink-0">
                {item.duration}
              </Typography>
            </Box>
          ))}
        </Box>
        <BulletListItem
          description={`회원 탈퇴 신청이 접수되면 위시켓 관리자가 확인 후 탈퇴를 처리할 예정입니다. 영업일 기준 최대 24시간 이내로 탈퇴가 완료되었음을 이메일로 알려드립니다.`}
        />
        <Box>
          <label className="flex items-center gap-3">
            <Checkbox
              checked={agreement}
              onChange={onAgreementChange}
              data-testid="feature-withdrawal--agreement-checkbox"
            />
            <Typography variant="body1" className="text-w-gray-900">
              탈퇴 시 유의사항을 모두 확인하였습니다.
            </Typography>
          </label>
          {!agreementError?.valid && (
            <Typography variant="body3" className="pl-[30px] text-w-red-500">
              {agreementError?.errorMessage}
            </Typography>
          )}
        </Box>
        <Box className="box-border flex w-full items-center gap-4">
          <Link
            className="flex-1 no-underline desktop:w-[170px] desktop:flex-none"
            href={withNextUrl(PATH.HOME)}
          >
            <Button
              variant="solid"
              className="w-full text-nowrap border-w-bluegray-200 bg-w-bluegray-200 hover:border-w-bluegray-300 hover:bg-w-bluegray-300"
            >
              취소
            </Button>
          </Link>
          <Button
            type="submit"
            variant="solid"
            className="w-full flex-1 desktop:w-[170px] desktop:flex-none"
          >
            회원 탈퇴 신청
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default WithdrawalRequest;
