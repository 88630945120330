'use client';

import { Box, Button, SystemIcon, Typography } from '@wishket/design-system';

import { Dialog, Icon } from '@/shared/Components';
import { SocialLoginProvider } from '@/shared/types';
import { generateSocialProviderName, maskEmail, maskId } from '@/shared/utils';

interface SocialLinkDupErrorDialogProps {
  onClickCloseButton: () => void;
  onClickSubmitButton: () => void;
  provider: SocialLoginProvider;
  user: { id: string; email: string };
}

const SocialLinkDupErrorDialog = ({
  onClickCloseButton,
  onClickSubmitButton,
  provider,
  user,
}: SocialLinkDupErrorDialogProps) => {
  const socialProviderName = generateSocialProviderName(provider);

  return (
    <Dialog className="h-auto min-h-[496px] w-[320px] rounded-2xl bg-w-white p-8 shadow-graymedium tablet:w-[400px] desktop:min-h-[430px] desktop:w-[520px]">
      <Box className="relative flex h-full w-full flex-col gap-8">
        <Box
          className="absolute right-0 top-0 cursor-pointer"
          onClick={onClickCloseButton}
        >
          <SystemIcon name="large_delete" />
        </Box>
        <Box className="flex flex-col items-center justify-center gap-3 desktop:w-full">
          <Icon name="unplugged" />
          <Box className="flex flex-col items-center justify-center desktop:flex-row">
            <Typography
              variant="subTitle2"
              className="font-medium text-w-gray-900"
            >
              {`해당 ${socialProviderName} 계정이 다른`}&nbsp;
            </Typography>
            <Typography
              variant="subTitle2"
              className="font-medium text-w-gray-900"
            >
              {`위시켓 계정과 연동되어 있어요!`}
            </Typography>
          </Box>
          <Box className="flex flex-col items-center justify-center desktop:flex-row">
            <Typography variant="body1" className="font-normal text-w-gray-700">
              {`다른 위시켓 계정의 ${socialProviderName} 연동을`}&nbsp;
            </Typography>
            <Typography variant="body1" className="font-normal text-w-gray-700">
              {`해제해 주세요.`}
            </Typography>
          </Box>
        </Box>
        <Box className="flex flex-col gap-1.5 rounded-2xl bg-w-gray-10 px-6 py-5">
          <Box className="flex items-start justify-start gap-4">
            <Typography
              variant="body2"
              className="w-10 break-keep font-medium text-w-gray-600"
            >
              아이디
            </Typography>
            <Typography
              variant="body2"
              className="h-auto break-all font-normal text-w-gray-600"
            >
              {maskId(user.id)}
            </Typography>
          </Box>
          <Box className="flex items-start justify-start gap-4">
            <Typography
              variant="body2"
              className="w-10 break-keep font-medium text-w-gray-600"
            >
              이메일
            </Typography>
            <Typography
              variant="body2"
              className="h-auto break-all font-normal text-w-gray-600"
            >
              {maskEmail(user.email)}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="solid"
          className="w-full"
          onClick={onClickSubmitButton}
        >
          확인
        </Button>
      </Box>
    </Dialog>
  );
};

export default SocialLinkDupErrorDialog;
