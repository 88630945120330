'use client';

import Link from 'next/link';
import { ChangeEvent, FormEvent, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from '@wishket/design-system';

import { MarketingAgreementCheckboxes, ToolTip } from './SignUpForm.parts';

import {
  ContentsContainer,
  Icon,
  PolicyModal,
  SocialFormButton,
} from '@/shared/Components';
import {
  SignUpInputErrorType,
  SignUpInputValueType,
  SocialLoginProvider,
} from '@/shared/types';
import { useNextUrl, usePolicyModal } from '@/shared/hooks';
import { PATH } from '@/shared/constants';

interface SignUpProps {
  inputValue: SignUpInputValueType;
  inputError: SignUpInputErrorType;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  submitSocialSignUp: (provider: SocialLoginProvider) => void;
}

const SignUpForm = ({
  inputValue,
  inputError,
  handleChange,
  handleSubmit,
  submitSocialSignUp,
}: SignUpProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const { withNextUrl } = useNextUrl();
  const {
    isPolicyModalOpen,
    openPolicyModal,
    closePolicyModal,
    policyModalType,
  } = usePolicyModal();

  const openTooltip = () => {
    setIsTooltipVisible(true);
  };

  const closeTooltip = () => {
    setIsTooltipVisible(false);
  };

  const toggleTooltip = () => {
    setIsTooltipVisible((prev) => !prev);
  };

  return (
    <>
      <PolicyModal
        isOpen={isPolicyModalOpen}
        onClose={closePolicyModal}
        type={policyModalType}
      />
      <ContentsContainer
        title="회원가입"
        testId="account-signup-form--container"
      >
        <Box className="flex flex-col gap-4">
          <form
            id="sign_up"
            className="flex flex-col gap-8"
            onSubmit={handleSubmit}
          >
            <Box className="flex flex-col gap-6">
              <Box className="relative flex flex-col gap-2">
                <TextField
                  name="email"
                  variant="lable"
                  lable="이메일"
                  value={inputValue.email}
                  onChange={handleChange}
                  isError={!inputError.email.valid}
                  errorMessage={inputError.email.errorMessage}
                />
                <Typography
                  variant="body2"
                  className="box-border flex w-full items-center gap-2 px-4 text-w-gray-400"
                >
                  비즈니스용 이메일 사용을 권장합니다.
                  <Box
                    onMouseOver={openTooltip}
                    onMouseOut={closeTooltip}
                    onClick={toggleTooltip}
                    data-testid="account-signup-form--info-icon"
                    className="mt-[0.5px] w-4 desktop:mt-0"
                  >
                    <Icon name="input_info" />
                  </Box>
                  {isTooltipVisible && (
                    <Box
                      className={twMerge(
                        'absolute left-0 top-[100px] z-20 tablet:left-[230px]',
                        !inputError.email.valid && 'desktop:top-[118px]'
                      )}
                      data-testid="account-signup-form--tooltip"
                    >
                      <ToolTip
                        title="입력한 이메일로 위시켓 서비스 이용 시 관련 알림이 발송됩니다."
                        description="예) 위시켓 프로젝트 알림, 요즘IT 뉴스레터 알림"
                        closeTooltip={closeTooltip}
                      />
                    </Box>
                  )}
                </Typography>
              </Box>

              <TextField
                name="id"
                variant="lable"
                lable="아이디"
                value={inputValue.id}
                onChange={handleChange}
                isError={!inputError.id.valid}
                errorMessage={inputError.id.errorMessage}
                maxLength={20}
              />
              <TextField
                name="password"
                type="password"
                variant="lable"
                lable="비밀번호"
                value={inputValue.password}
                onChange={handleChange}
                isError={!inputError.password.valid}
                errorMessage={inputError.password.errorMessage}
                supportMessage="8자 이상 32자 이하로 입력해 주세요."
                maxLength={32}
                isMaxLengthVisible={false}
              />
              <TextField
                type="password"
                name="passwordConfirm"
                variant="lable"
                lable="비밀번호 확인"
                value={inputValue.passwordConfirm}
                onChange={handleChange}
                isError={!inputError.passwordConfirm.valid}
                errorMessage={inputError.passwordConfirm.errorMessage}
                maxLength={32}
                isMaxLengthVisible={false}
              />
            </Box>
            <MarketingAgreementCheckboxes
              isCheckPolicyAgreement={inputValue.policyAgreement}
              isCheckMarketingUseAgreement={inputValue.marketingUseAgreement}
              isCheckMarketingSubscribeAgreement={
                inputValue.marketingSubscribeAgreement
              }
              handleChange={handleChange}
              openPolicyModal={openPolicyModal}
              isError={!inputError.policyAgreement.valid}
            />
            <Button variant="solid" className="mt-2 w-full" type="submit">
              회원가입
            </Button>
          </form>
          <Box className="flex flex-col justify-center gap-6">
            <Box className="flex w-full items-center justify-center">
              <Divider className="w-[40%]" />
              <Typography
                variant="caption1"
                className="mx-3 flex w-14 justify-center text-w-gray-500"
              >
                또는
              </Typography>
              <Divider className="w-[40%]" />
            </Box>
            <Box className="flex flex-col gap-4">
              <SocialFormButton
                type="google"
                text="구글로 회원가입"
                onClick={() => submitSocialSignUp('google')}
              />
              <SocialFormButton
                type="facebook"
                text="페이스북으로 회원가입"
                onClick={() => submitSocialSignUp('facebook')}
              />
            </Box>
            <Typography
              variant="body1"
              className="text-center font-normal text-w-gray-600"
            >
              이미 위시켓 통합회원이신가요? &nbsp;
              <Link
                href={withNextUrl(PATH.LOGIN)}
                className="font-medium text-w-primary no-underline"
              >
                로그인
              </Link>
            </Typography>
          </Box>
        </Box>
      </ContentsContainer>
    </>
  );
};

export default SignUpForm;
