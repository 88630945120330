'use client';

import { Box, Button, TextField, Typography } from '@wishket/design-system';
import Image from 'next/image';
import { ChangeEvent, FormEvent } from 'react';
import Link from 'next/link';

import { MarketingAgreementCheckboxes } from './SignUpForm.parts';

import { ContentsContainer, PolicyModal } from '@/shared/Components';
import {
  SocialLoginProvider,
  SocialSignUpInputErrorType,
  SocialSignUpInputValueType,
} from '@/shared/types';
import { generateSocialProviderName } from '@/shared/utils';
import { PATH } from '@/shared/constants';
import { useNextUrl, usePolicyModal } from '@/shared/hooks';

interface SocialSignUpFormProps {
  provider: SocialLoginProvider;
  inputValue: SocialSignUpInputValueType;
  inputError: SocialSignUpInputErrorType;
  handleChange: {
    id: (e: ChangeEvent<HTMLInputElement>) => void;
    password: (e: ChangeEvent<HTMLInputElement>) => void;
    passwordConfirm: (e: ChangeEvent<HTMLInputElement>) => void;
    agreement: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const SocialSignUpForm = ({
  provider,
  inputValue,
  inputError,
  handleChange,
  onSubmit,
}: SocialSignUpFormProps) => {
  const providerName = generateSocialProviderName(provider);
  const { withNextUrl } = useNextUrl();
  const {
    isPolicyModalOpen,
    openPolicyModal,
    closePolicyModal,
    policyModalType,
  } = usePolicyModal();

  return (
    <>
      <PolicyModal
        isOpen={isPolicyModalOpen}
        onClose={closePolicyModal}
        type={policyModalType}
      />
      <ContentsContainer
        title={`위시켓 ${providerName} 통합회원가입`}
        testId="feature--social_signUp_form"
      >
        <Box className="flex flex-col items-start justify-center gap-6">
          <Box className="mb-2 flex flex-col gap-2">
            <Box className="flex items-center justify-start gap-[6px]">
              <Typography variant="subTitle2" className="font-medium">
                가입 마지막 단계입니다.
              </Typography>
              <Image
                src={`${process.env.NEXT_PUBLIC_APP_BASE_MEDIA_URL}/images/auth/icon_emoji_arm.png`}
                alt="arm emoji"
                width="20"
                height="20"
                unoptimized
              />
            </Box>
            <Typography variant="body1" className="text-w-gray-600">
              위시켓 서비스 이용을 위해 추가 정보를 입력해 주세요.
            </Typography>
          </Box>
          <form
            id="social_sign_up"
            className="flex w-full flex-col gap-6"
            onSubmit={onSubmit}
          >
            <Box className="flex flex-col gap-6">
              <TextField
                name="id"
                variant="lable"
                lable="아이디"
                value={inputValue.id}
                onChange={handleChange.id}
                isError={!inputError.id.valid}
                errorMessage={inputError.id.errorMessage}
              />
              <TextField
                name="password"
                type="password"
                variant="lable"
                lable="비밀번호"
                value={inputValue.password}
                onChange={handleChange.password}
                isError={!inputError.password.valid}
                errorMessage={inputError.password.errorMessage}
                supportMessage="8자 이상 32자 이하로 입력해 주세요."
                maxLength={32}
                isMaxLengthVisible={false}
              />
              <TextField
                type="password"
                name="passwordConfirm"
                variant="lable"
                lable="비밀번호 확인"
                value={inputValue.passwordConfirm}
                onChange={handleChange.passwordConfirm}
                isError={!inputError.passwordConfirm.valid}
                errorMessage={inputError.passwordConfirm.errorMessage}
                maxLength={32}
                isMaxLengthVisible={false}
              />
            </Box>
            <MarketingAgreementCheckboxes
              isCheckPolicyAgreement={inputValue.policyAgreement}
              isCheckMarketingUseAgreement={inputValue.marketingUseAgreement}
              isCheckMarketingSubscribeAgreement={
                inputValue.marketingSubscribeAgreement
              }
              handleChange={handleChange.agreement}
              openPolicyModal={openPolicyModal}
              isError={!inputError.policyAgreement.valid}
            />
            <Button
              variant="solid"
              className="mt-2 w-full"
              type="submit"
              data-testid="social-signup-submit-button"
            >
              회원가입
            </Button>
          </form>
          <Box className="flex w-full items-center justify-center">
            <Typography
              variant="body1"
              className="text-center font-normal text-w-gray-600"
            >
              이미 위시켓 통합회원이신가요? &nbsp;
              <Link
                href={withNextUrl(PATH.LOGIN)}
                className="font-medium text-w-primary no-underline"
              >
                로그인
              </Link>
            </Typography>
          </Box>
        </Box>
      </ContentsContainer>
    </>
  );
};

export default SocialSignUpForm;
