'use client';

import { Box, Button, TextField, Typography } from '@wishket/design-system';
import { ChangeEvent, FormEvent } from 'react';

import { PasswordChangeType } from '@/shared/types';

interface PasswordVerifyFormProps extends PasswordChangeType {
  title: string;
  onPasswordChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
}

const PasswordVerifyForm = ({
  title,
  errorStatus,
  inputValue,
  onPasswordChange,
  onSubmit,
}: PasswordVerifyFormProps) => {
  return (
    <>
      <form
        className="flex flex-col gap-10"
        key="password-authentication"
        data-testid="password-verify-form"
        onSubmit={onSubmit}
      >
        <Box className="flex flex-col gap-2">
          <Typography variant="subTitle1" className="desktop:text-2xl">
            {title}
          </Typography>
          <Typography variant="body1" className="text-w-gray-600">
            소중한 개인정보를 안전하게 보호하기 위해 다시 한번 인증해 주세요.
          </Typography>
        </Box>
        <Box className="flex flex-col gap-2 desktop:w-[460px]">
          <TextField
            type="password"
            name="currentPassword"
            variant="lable"
            lable="비밀번호"
            isError={!errorStatus?.currentPassword?.valid}
            supportMessage="8자 이상 32자 이하로 입력해 주세요."
            errorMessage={errorStatus?.currentPassword?.errorMessage}
            value={inputValue?.currentPassword}
            onChange={onPasswordChange}
            maxLength={32}
            isMaxLengthVisible={false}
          />
        </Box>
        <Button
          type="submit"
          variant="solid"
          size="md"
          className="w-full desktop:w-[120px]"
        >
          인증하기
        </Button>
      </form>
    </>
  );
};

export default PasswordVerifyForm;
