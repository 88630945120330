import { MenuItem } from './ServiceMenu.types';

export const menuList: MenuItem[] = [
  {
    name: '위시켓',
    icon: 'wishket_symbol',
    description: 'IT 아웃소싱 플랫폼',
    link: 'https://www.wishket.com/mywishket/partners/',
  },
  {
    name: '위시켓 잡스',
    icon: 'jobs_symbol',
    description: '개발자 정규직 채용 플랫폼',
    badge: '서비스 준비 중',
    link: '',
  },
  {
    name: '요즘IT',
    icon: 'yozmIT_symbol',
    description: '성장을 위한 IT 매거진',
    link: 'https://yozm.wishket.com/magazine/',
  },
];
